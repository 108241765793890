import React from "react";
import "./Hero.css";
import Disclaimer from "../utils/Disclaimer";

function Hero() {
  const main = process.env.REACT_APP_MAIN;

  return (
    <div className="hero-wrapper">
      <div className="hero-overlay"></div>
      <div className="hero-container">
        <div className="hero-content">
          <h1>
            Conheça o nosso <span>
              Relatório de Inteligência
              Privada
            </span>
          </h1>
          <p>
            O Relatório de Inteligência Privada (RIP) consiste na entrega de um
            dossiê completo sobre qualquer alvo (Pessoas Físicas e/ou Pessoas
            Jurídicas).
          </p>
          {/* <p>
            Utilizamos a busca massiva de informações em bancos de dados em fontes abertas, técnica também conhecida como OSINT - Open Source Inteligence (ou, Inteligência em Fontes Abertas),
            combinada com o trabalho manual para obter informações refinadas.
          </p> */}
          <p>
            Trabalhamos com as melhores ferramentas nacionais e internacionais, o que aliado ao trabalho artesanal empreendido pelos nossos experientes operadores, resulta no melhor refinamento possível da informação almejada.
          </p>
          <a
            href="https://wa.me/5584999827007"
            target="_blank"
            rel="noreferrer"
            className='main-btn'>entre em contato</a>
        </div>
        <Disclaimer />
      </div>
    </div>
  );
}

export default Hero;
