import "./Footer.css";
import React, { useMemo } from "react";
import Logo from '../../assets/imgs/ethos-brasil.png';
import menuItems from './Btns-Content';
import MenuBtn from './MenuBtn';
import Demo from '../newsletter/Demo';

const Footer = ({ scrollToComponent }) => {

  const action = (address) => {
    if (address !== "Link") {
      scrollToComponent(address);
    } else {
      scrollUp();
    }
  };

  const scrollUp = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const memoizedMenuItems = useMemo(() => {
    return menuItems.map((menuItem, index) => (

      <MenuBtn className="footer-menu-btn" action={action} data={menuItem} scrollToComponent={scrollToComponent} />

    ));
  }, [menuItems]);

  return (
    <footer className="footer-background">
      <div className="footer-container header-body-footer-width">
        <div class="footer-container-division">

          <div className="footer-wrap">
            <div className="footer-logo">
              <img className="footer-img" src={Logo} alt="logo"></img>
            </div>
            <div></div>
            <div>
              <Demo />
            </div>
          </div>
          <div className="footer-wrap footer-content">
            <div className="footer-endereco">
              <h5>ENCONTRE-NOS</h5>
              <p>
                <span>Endereço</span>
                OTC - Office Tower Center, Sala 303
                <br></br>
                Rua Promotor Manoel Alves Pessoa Neto, 45
                <br></br>
                Candelária, Natal, CEP: 59065-555
                <br></br>
                <br></br>
                <br></br>
                CNPJ n.º 35.398.443/0001-67
                <br></br>
                <br></br>
                ETHOS BRASIL SUPORTE A LITIGIOS LTDA
              </p>
            </div>
            <div className="footer-menu">
              <h5>NAVEGAÇÃO</h5>
              <div>
                {memoizedMenuItems}
              </div>
            </div>
            <div className="footer-menu">
              <h5>ÁREAS E ESPECIALIDADES</h5>
              <div>
                <p>
                  Consultoria em Tecnologia da Informação
                </p>
                <p>Auditoria e Consultoria Atuarial</p>
                <p>Vigilância e Segurança Privada</p>
                <p>Peritos e Serviços Especializados</p>
                <p>Preparação e Custódia de Documentos</p>
                <p></p>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-wrap footer-bottom-container">
          <div className="footer-copy">
            <p>&copy; 2024 Ethos Brasil.</p>
          </div>
          <div className="footer-socials">
            <a
              href="https://www.linkedin.com/company/ethos-brasilorg/"
              target="_blank"
              rel="noreferrer"
              aria-label="site principal do escritório"
            >
              <i className="uil uil-linkedin"></i>
            </a>

            <a
              href="https://www.instagram.com/ethosbrasil_org/"
              target="_blank"
              aria-label="site principal do escritório"
              rel="noreferrer"
            >
              <i className="uil uil-instagram"></i>
            </a>

            <a
              href="https://wa.me/5584999827007"
              aria-label="Whatsapp Icon"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa fa-whatsapp"></i>
            </a>

            <a
              href="mailto:contato@ethosbrasil.org"
              target="_blank"
              rel="noreferrer"
              aria-label="site principal do escritório"
            >
              <i class="uil uil-envelope"></i>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
