import React from 'react';
import './PrevinaRiscos.css';

function Previna() {
  return (
    <div className='previna-wrapper'>
      <div className='previna-container'>
        <div className='previna-content'>
          <h2>Mitigação de riscos</h2>
          <p>Nossos relatórios de investigação fornecem insights cruciais para a identificação e avaliação de potenciais ameaças, permitindo que você tome medidas proativas para proteger-se.</p>
          <p>Com uma equipe multidisciplinar altamente qualificada e experiente, a Ethos Brasil oferece um serviço completo de investigação, desde a coleta e análise de dados até a produção de relatórios detalhados e personalizados.</p>
          <p>Com nosso Relatório de Investigação Privada (RIP), você estará munido das informações e ferramentas necessárias para navegar qualquer situação desafiadora.</p>
          <a
            href="https://wa.me/5584999827007"
            target="_blank"
            rel="noreferrer"
            className='main-btn'>entre em contato</a>
        </div>
        <div className='previna-img'>
        </div>
      </div>
    </div>
  );
}

export default Previna;
