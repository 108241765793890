import React from 'react';
import './Sobre.css';

function Sobre() {
  return (
    <div className='sobre-wrapper'>
        <div className='sobre-container'>
          <div className='sobre-content'>
            <h2>Ethos Brasil</h2>
          <p>A Ethos Brasil tem como objetivo principal auxiliar seus clientes na conquista de seus objetivos em processos judiciais e tratativas extrajudiciais</p>
          <p>Para isso, oferecemos um conjunto de serviços abrangentes que visam fortalecer a posição do cliente e aumentar suas chances de sucesso.</p>
          <p>Atuamos com a coleta, análise, organização e apresentação de evidências digitais e documentais, de maneira multidisciplinar.</p>
          <a
            href="https://wa.me/5584999827007"
            target="_blank"
            rel="noreferrer"
            className='main-btn'>entre em contato</a>
          </div>
          <div className='sobre-img'>
          </div>
        </div>
    </div>
  );
}

export default Sobre;
