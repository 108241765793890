import React, { useRef } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/shared/Header";
import Footer from "./components/shared/Footer";
import "./App.css";
import "./assets/styles/Reset.css";
import Hero from './components/hero/Hero'
import PontosFortes from './components/PontosFortes/PontosFortes';
import FAQ from './components/FAQ/FAQ';
import UniversoInformacoes from './components/UniversoInformacoes/UniversoInformacoes';
import Depoimentos from './components/Depoimentos/Depoimentos';
import PrevinaRiscos from './components/PrevinaRiscos/PrevinaRiscos';
import Sobre from './components/Sobre/Sobre';
import Parceiros from './components/Parceiros/Parceiros';
import SobreRIP from './components/SobreRIP/Sobre';
import CTA from './components/CTA/CTA';



function App() {

  const main = process.env.REACT_APP_MAIN;

  const inicio = useRef();
  const rip = useRef();
  const depoimentos = useRef();
  const newsletter = useRef();

  const scrollToComponent = (refName) => {
    const refs = {
      inicio,
      rip,
      depoimentos,
      newsletter,
    };

    const selectedRef = refs[refName];

    if (selectedRef && selectedRef.current) {
      var yOffset;
      if (refName === "InicioRef") {
        yOffset = -180;
      } else {
        yOffset = -120;
      }

      const yCoordinate =
        selectedRef.current.getBoundingClientRect().top +
        window.scrollY +
        yOffset;
      window.scrollTo({ top: yCoordinate, behavior: "smooth" });
    }
  };

  return (
    <Router>
      <div className="App">
        <Header scrollToComponent={scrollToComponent} />

        <div class="body-wrapper">
          <div class="body-container">

            <div ref={inicio}>
              <Hero />
            </div>
            <PontosFortes />
            <div ref={rip}>
              <SobreRIP />
            </div>
            <PrevinaRiscos />
            <UniversoInformacoes />
            <div ref={depoimentos}>
              <Depoimentos />
            </div>
            
            <FAQ />
            <CTA />
            <Sobre />

          </div>
        </div>


        <div className="whats-btn">
          <a
            href="https://wa.me/5584999827007"
            target="_blank"
            rel="noreferrer"
          >
            <i class="uil uil-whatsapp whats"></i>
          </a>
        </div>
        <div ref={newsletter}>

          <Footer scrollToComponent={scrollToComponent} />
        </div>
      </div>
    </Router>
  );
}

export default App;
