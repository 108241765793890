const type = "Button";

const menuItems = [
    {
        label: "Início",
        type: type,
        to: "inicio",
    },
    {
        label: "Informações",
        type: type,
        to: "rip",

    },
    {
        label: "Depoimentos",
        type: type,
        to: "depoimentos",
    },
    {
        label: "Newsletter",
        type: type,
        to: "newsletter",
    }
];

export default menuItems;
