import React from 'react';
import './Sobre.css';

function Sobre() {
  return (
    <div className='sobrerip-wrapper'>
      <div className='sobrerip-container'>
        <div className='sobrerip-content'>
          <h2>Relatório de Inteligência Privada (RIP)</h2>
          <p>O RIP, ou Relatório de Inteligência Privada, é recomendado para abordagens que exigem informações detalhadas sobre um alvo, seja uma pessoa física ou jurídica.</p>
          <p>Esse processo envolve a mineração de dados, com o uso de ferramentas avançadas nacionais e internacionais, aliado ao trabalho artesanal empreendido pelos nossos experientes operadores.</p>
          <p>A gama de informações fornecidas no RIP é abrangente e inclui dados como registros civis e societários, localizações conhecidas, histórico financeiro, informações de crédito, antecedentes criminais, entre outros.</p>
          <a
            href="https://wa.me/5584999827007"
            target="_blank"
            rel="noreferrer"
            className='main-btn'>entre em contato</a>
        </div>
        <div className='sobrerip-img'>
          {/*  */}
        </div>
      </div>
    </div>
  );
}

export default Sobre;
