import React from 'react';
import './Depoimentos.css';

function Depoimentos() {
  return (
    <div className='depoimentos-container'>
        <div className="depoimentos-content">
          <h2>(Aqui ficará um slider com depoimentos)</h2>
        </div>
        <div className='overlay'></div>
    </div>
  );
}

export default Depoimentos;
